<template>
  <div>
      
    <b-modal
      ref="modal-webinar-message"
      id="modal-webinar-message"
      title="Envoyer un message"
      size="lg"
      @ok="sendEmail(email)"
    >   
      <label> Objet </label>
      <b-form-input
        placeholder="Saisir l'objet du mail"
        class="mb-1"
        v-model="email.subject"
      >
      </b-form-input>

     <quill-editor 
     v-model="email.content">
     </quill-editor>

    </b-modal>
   
  </div>
</template>

<script>
import { BModal, BFormInput } from "bootstrap-vue";
import { Admin } from "@/mixins/admin.js";

import { quillEditor } from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  name: "WebinarMessageModal",

  components: {
    BModal,
    quillEditor,
    BFormInput
  },

  props: {
    speakerData: {
      lastname: "",
      firstname: "",
      email: "",
      biography: "",
      speciality: "",
      status: "",
    },
  },

  mixins: [Admin],

  data() {
    return {
      content: `...`,
      email : {
        subject: "", 
        content : ""
      }   
         
    };
  },

  methods: {
    async sendEmail(item) {
      this.$emit("sendEmail", item)
    },
  },

};
</script>

<style>
.ql-editor {
    min-height: 300px;
}
</style>