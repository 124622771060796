<template>
	<div>
		<AddHeaderTitle :Header="Header" />
		<AppHeaderInfoAdminList :infos="infos" />

		<b-row align-h="start" v-if="adminRole == 1">
			<b-button
				variant="primary "
				class="mb-2 mr-2"
				v-b-modal.modal-webinar-create
			>
				<feather-icon icon="PlusIcon" class="mr-50" />
				<span class="align-left">Créer un webinaire </span>
			</b-button>
		</b-row>

		<b-table striped hover :items="list" :fields="fields" @row-clicked="myRowClickHandler">
			<template #cell(speakers)="list">
				<span v-if="list.item.speakers.length > 0">
					<span v-for="speaker in list.item.speakers" :key="speaker.id">
						<b-badge pill variant="light-primary" class="text-capitalize">
							<feather-icon icon="Volume2Icon" />
							{{ speaker.name }}
						</b-badge>
					</span>
				</span>
				<span v-else>
					<b-badge pill variant="light-danger">
						<feather-icon icon="UserXIcon" />
						aucun speaker
					</b-badge>
				</span>
			</template>

			<template #cell(start_date)="list">
				{{ getDate(list.item.start_date) }}
			</template>

			<template #cell(action)="list">
				<b-dropdown variant="link" toggle-class="p-0" no-caret right>
					<template #button-content>
						<feather-icon
							icon="MoreVerticalIcon"
							size="16"
							class="align-middle text-body"
						/>
					</template>
					<b-dropdown-item @click="editWebinar(list.item.id)">
						<feather-icon icon="EditIcon" />
						<span class="align-middle ml-50">Editer</span>
					</b-dropdown-item>
					<b-dropdown-item @click="recordWebinar(list.item.id)">
						<feather-icon icon="EditIcon" />
						<span class="align-middle ml-50">Voir les enregistrements</span>
					</b-dropdown-item>
					<b-dropdown-item
						v-b-modal.modal-webinar-message
						@click="sendInfo(list.item)"
					>
						<feather-icon icon="MessageSquareIcon" />
						<span class="align-middle ml-50">Envoyer un message</span>
					</b-dropdown-item>
					<b-dropdown-item
						@click="seeViewer(list.item.id)"
						v-if="adminRole == 1"
					>
						<feather-icon icon="UsersIcon" />
						<span class="align-middle ml-50">Liste des participants</span>
					</b-dropdown-item>
					<b-dropdown-item @click="seeSurvey(list.item)" v-if="adminRole == 1">
						<feather-icon icon="BarChart2Icon" />
						<span class="align-middle ml-50">Réponses au survey</span>
					</b-dropdown-item>
					<b-dropdown-item @click="seeQuiz(list.item, 1)" v-if="adminRole == 1">
						<feather-icon icon="CheckSquareIcon" />
						<span class="align-middle ml-50">Réponses au PreQuiz</span>
					</b-dropdown-item>
					<b-dropdown-item @click="seeQuiz(list.item, 2)" v-if="adminRole == 1">
						<feather-icon icon="CheckSquareIcon" />
						<span class="align-middle ml-50">Réponses au PostQuizz</span>
					</b-dropdown-item>

					<b-dropdown-item @click="loginFront(list.item.id, list.item.slug)">
						<feather-icon icon="LogInIcon" />
						<span class="align-middle ml-50">Se connecter au webinaire</span>
					</b-dropdown-item>

					<b-dropdown-item
						v-b-modal.modal-quiz
						@click="sendInfo(list.item)"
						v-if="adminRole == 1"
					>
						<feather-icon icon="MailIcon" />
						<span class="align-middle ml-50">Tester les emails</span>
					</b-dropdown-item>

					<b-dropdown-item
						v-b-modal.modal-sm
						@click="sendInfo(list.item)"
						v-if="adminRole == 1"
					>
						<feather-icon icon="TrashIcon" />
						<span class="align-middle ml-50">Supprimer</span>
					</b-dropdown-item>
				</b-dropdown>
			</template>
		</b-table>

		<b-modal id="modal-quiz" centered size="sm" title="Tester les emails">
			<template #default>
				<label> Survey / Quiz </label>
				<b-form-select
					v-model="testQuiz.type"
					:options="listSurvey"
				></b-form-select>
				<br /><br />
				<label> Email </label>
				<b-form-input
					v-model="testQuiz.email"
					placeholder="email"
				></b-form-input>
			</template>
			<template #modal-footer="{ cancel }">
				<b-button size="sm" variant="danger" @click="cancel()">
					Annuler
				</b-button>
				<b-button
					size="sm"
					variant="primary"
					@click="testEmail(webinar.id, testQuiz)"
				>
					Envoyer
				</b-button>
			</template>
		</b-modal>

		<b-modal id="modal-sm" centered size="sm" title="Information">
			<template #default>
				Êtes-vous sûr de vouloir supprimer le webinar "{{ webinar.title }}" ?
			</template>
			<template #modal-footer="{ cancel }">
				<b-button size="sm" variant="primary" @click="cancel()">
					Annuler
				</b-button>
				<b-button
					size="sm"
					variant="danger"
					@click="deleteWebinarId(webinar.id)"
				>
					Supprimer
				</b-button>
			</template>
		</b-modal>

		<webinar-create-modal @clicked="onCreate"> </webinar-create-modal>

		<webinar-message-modal @sendEmail="sendEmail"> </webinar-message-modal>
	</div>
</template>

<script>
import AddHeaderTitle from "@/app/views/components/AppHeaderTitle.vue";
import AppHeaderInfoAdminList from "@/app/views/components/AppHeaderInfoAdminList.vue";
import {
	BTable,
	BButton,
	BRow,
	BModal,
	BDropdown,
	BDropdownItem,
	BBadge,
	BFormInput,
	BFormSelect,
} from "bootstrap-vue";
import { Admin } from "@/mixins/admin.js";
import WebinarCreateModal from "./webinar-create-modal.vue";
import WebinarMessageModal from "./webinar-message-modal.vue";
import moment from "moment";

export default {
	name: "Webinars",

	components: {
		WebinarCreateModal,
		WebinarMessageModal,
		BTable,
		AddHeaderTitle,
		AppHeaderInfoAdminList,
		BButton,
		BRow,
		BFormInput,
		BModal,
		BDropdown,
		BDropdownItem,
		BBadge,
		BFormSelect,
	},

	data() {
		return {
			Header: {
				config: "Admin",
				listLink: "/admin/webinar/list",
				list: "Webinar-liste",
			},
			listSurvey: [
				{
					text: "Survey",
					value: "survey",
				},
				{
					text: "Pré-quiz",
					value: "prequizz",
				},
				{
					text: "Post-quiz",
					value: "postquizz",
				},
			],
			infos: {
				title: "Liste des webinars",
				content: "Liste et informations sur les webinars",
			},
			fields: [
				{
					key: "title",
					label: "Titre",
					sortable: true,
				},
				{
					key: "speakers",
					label: "Speakers",
					sortable: true,
				},
				{
					key: "start_date",
					label: "Date",
					sortable: true,
				},
				{
					key: "price",
					label: "Prix €",
					sortable: true,
				},
				{
					key: "Action",
					label: "Action",
					sortable: false,
				},
			],
			list: [],
			webinar: {},
			testQuiz: {},
			adminRole: this.$store.state.user.user.role,
		};
	},

	mixins: [Admin],

	mounted() {
		this.loadList();
	},

	methods: {
		async loadList() {
			const response = await this.getAllWebinars();
			this.list = response.datas;
		},

		onCreate(value) {
			//value.speakers = []
			console.log("new webinar", value);
			this.list.push(value);
		},

		sendInfo(webinarInfo) {
			this.webinar = webinarInfo;
		},

		myRowClickHandler(record, index) {
		// 	this.editWebinar(record.id)
		},

		async deleteWebinarId(idWebinar) {
			let loader = this.$loading.show({
				container: this.$refs.formContainer,
				canCancel: true,
			});
			this.$bvModal.hide("modal-sm");
			try {
				const response = await this.deleteWebinar(idWebinar);
				if (response.status == 1) {
					this.$sweetNotif("Modification réussie");
					this.list = this.list.filter((webinar) => webinar.id !== idWebinar);
				} else {
					this.$sweetError("AF-51");
				}
				loader.hide();
			} catch {
				loader.hide();
				this.$sweetError("AF-51");
			}
		},

		seeViewer(id) {
			this.$router.push({ path: `/admin/viewer/list/${id}` });
		},

		editWebinar(id) {
			this.$router.push({ path: `/admin/webinar/${id}` });
		},
		recordWebinar(id) {
			this.$router.push({ path: `/admin/webinar/record/${id}` });
		},
		seeSurvey(webinar) {
			this.$router.push({
				path: `/admin/viewer/survey/${webinar.id}/${webinar.survey_id}`,
			});
		},
		seeQuiz(webinar, type) {
			switch (type) {
				case 1:
					this.$router.push({
						path: `/admin/viewer/quiz/${webinar.id}/${webinar.pre_quizz}`,
					});
					break;
				case 2:
					this.$router.push({
						path: `/admin/viewer/quiz/${webinar.id}/${webinar.post_quizz}`,
					});
					break;
			}
		},

		async sendEmail(email) {
			email.webinar_id = this.webinar.id;
			let loader = this.$loading.show({
				container: this.$refs.formContainer,
				canCancel: true,
			});
			try {
				const response = await this.sendWebinarEmail(email);
				if (response.status === 1) {
					this.$sweetNotif("Envoi email réussi");
				} else {
					this.$sweetError("SEND-EMAIL");
				}
				loader.hide();
			} catch {
				loader.hide();
				this.$sweetError("SEND-EMAIL-1");
			}
		},

		async testEmail(id, info) {
			let loader = this.$loading.show({
				container: this.$refs.formContainer,
				canCancel: true,
			});
			try {
				const response = await this.sendTestEmail(id, info.type, info.email);
				if (response.status === 1) {
					this.$sweetNotif("Envoi email réussi");
				} else {
					this.$sweetError("SEND-EMAIL");
				}
				loader.hide();
			} catch {
				loader.hide();
				this.$sweetError("SEND-EMAIL-1");
			}
		},

		async loginFront(id, slug) {
			let loader = this.$loading.show({
				container: this.$refs.formContainer,
				canCancel: true,
			});
			try {
				const response = await this.loginToFront(id);

				if (response.status === 1) {
					this.$sweetNotif(
						"Si l'onglet ne s'ouvre pas, vérifier que votre navigateur ne le bloque pas."
					);
					let url =
						"https://aba-live.fr/authentificate/" +
						slug +
						"?t=" +
						response.datas.token +
						"&admin=true";
					console.log(url);
					window.open(url, "_blank");
				} else {
					this.$sweetError("Une erreur s'est produite");
				}
				loader.hide();
			} catch {
				loader.hide();
				this.$sweetError("Une erreur s'est produite");
			}
		},

		getDate(date) {
			return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
			// moment.locale('fr');
			// return moment(date).startOf('day').fromNow()
		},
	},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
