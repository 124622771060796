<template>
	<div>
		<b-modal
			ref="modal-webinar-create"
			id="modal-webinar-create"
			title="Créer un Webinar"
			size="lg"
		>
			<!-- Media -->
			<b-media class>
				<b-row>
					<b-col md="12" offset-md="4">
						<div align-h="center" class="mb-12">
							<b-avatar :src="my_photo" size="6rem"></b-avatar>
							<br />
							<br />
							<div id="app">
								<input type="file" @change="onFileInput($event)" />
							</div>
							<label> (png/jpeg)</label>
							<br />
						</div>
					</b-col>
				</b-row>
			</b-media>

			<ValidationObserver ref="observer">
				<b-form>
					<b-row>
						<b-col>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<label>Titre <span style="color:red">*</span></label>
								<b-form-textarea
									v-model="form.title"
									placeholder="Titre"
								></b-form-textarea>
								<small class="text-danger">{{ errors[0] }}</small>
							</ValidationProvider>
							<br />
							<br />
							<b-row>
								<b-col>
									<ValidationProvider rules="required" v-slot="{ errors }">
										<label
											>Tarif (en euro) <span style="color:red">*</span></label
										>
										<b-form-input
											type="number"
											v-model.number="form.price"
											placeholder="10"
											step="0.01"
										></b-form-input>
										<small class="text-danger">{{ errors[0] }}</small>
									</ValidationProvider>
								</b-col>
								<b-col>
									<b-form-group label="Enregistrement" label-for="Statut">
										<b-form-checkbox
											id="Statut"
											class="primary mt-1"
											name="check-button"
											switch
											v-model="form.recording"
										>
											<span class="switch-icon-left">
												<feather-icon icon="CheckIcon" />
											</span>
											<span class="switch-icon-right">
												<feather-icon icon="XIcon" />
											</span>
										</b-form-checkbox>
									</b-form-group>
								</b-col>
							</b-row>
						</b-col>

						<b-col>
							<ValidationProvider rules="required" v-slot="{ errors }">
								<label>Date du Webinar <span style="color:red">*</span></label>
								<b-form-datepicker
									id="example-datepicker"
									v-model="form.start_date"
								></b-form-datepicker>
								<small class="text-danger">{{ errors[0] }}</small>
							</ValidationProvider>
							<br />
							<ValidationProvider rules="required" v-slot="{ errors }">
								<label>Heure de début <span style="color:red">*</span></label>
								<!-- <b-time v-model="timeStart" locale="fr"></b-time> -->
								<b-form-input v-model="timeStart" type="time"></b-form-input>
								<small class="text-danger">{{ errors[0] }}</small>
							</ValidationProvider>
							<br />
							<ValidationProvider rules="required|numeric" v-slot="{ errors }">
								<label
									>Durée du webinar (en minutes)
									<span style="color:red">*</span></label
								>
								<b-form-input
									type="number"
									v-model="form.duration"
									placeholder="10"
									step="0.01"
								></b-form-input>
								<small class="text-danger">{{ errors[0] }}</small>
							</ValidationProvider>
						</b-col>
					</b-row>

					<br />
					<hr />

					<b-row>
						<b-col>
							<label>Type de webinar</label>
							<b-form-select
								v-model="form.type"
								:options="typeWebinar"
							></b-form-select>
						</b-col>

						<b-col>
							<ValidationProvider rules="required|numeric" v-slot="{ errors }">
								<label
									>Controle de présence (min)
									<span style="color:red">*</span></label
								>
								<b-form-input
									type="number"
									v-model="form.controle_frequency"
									placeholder="15"
								></b-form-input>
								<small class="text-danger">{{ errors[0] }}</small>
							</ValidationProvider>
						</b-col>
					</b-row>
					<br />
					<b-row>
						<b-col>
							<ValidationProvider rules="required|numeric" v-slot="{ errors }">
								<label>Point CEU <span style="color:red">*</span></label>
								<b-form-input
									type="number"
									v-model="form.point_ceu"
									placeholder="10"
									step="0.01"
								></b-form-input>
								<small class="text-danger">{{ errors[0] }}</small>
							</ValidationProvider>
						</b-col>
						<b-col>
							<ValidationProvider rules="required|numeric" v-slot="{ errors }">
								<label>Point CFC <span style="color:red">*</span></label>
								<b-form-input
									type="number"
									v-model="form.point_cfc"
									placeholder="10"
									step="0.01"
								></b-form-input>
								<small class="text-danger">{{ errors[0] }}</small>
							</ValidationProvider>
						</b-col>

						<b-col>
							<ValidationProvider rules="required|numeric" v-slot="{ errors }">
								<label
									>Min. Score (en %) <span style="color:red">*</span></label
								>
								<b-form-input
									type="number"
									v-model="form.minimal_certificat_score"
									placeholder="10"
									step="0.01"
								></b-form-input>
								<small class="text-danger">{{ errors[0] }}</small>
							</ValidationProvider>
						</b-col>
					</b-row>
					<br />
					<b-row>
						<b-col>
							<label>Le Survey</label>
							<b-form-select
								v-model="form.survey_id"
								:options="listSurvey"
							></b-form-select>
						</b-col>
					</b-row>
					<br />
					<b-row>
						<b-col>
							<label>Pré-Quizz</label>
							<b-form-select
								v-model="form.pre_quizz"
								:options="listQuiz"
							></b-form-select>
						</b-col>
						<b-col>
							<label>Post-Quizz</label>
							<b-form-select
								v-model="form.post_quizz"
								:options="listQuiz"
							></b-form-select>
						</b-col>
					</b-row>
					<br />
					<hr />
					<br />
					<b-row>
						<b-col>
							<label>Objectifs pédagogiques</label>
							<b-form-textarea
								v-model="form.learning_objective"
								placeholder="Objectif"
							></b-form-textarea>
							<br />
						</b-col>
						<b-col>
							<label>Information générale</label>
							<b-form-textarea
								v-model="form.learning_content"
								placeholder="Learning content"
							></b-form-textarea>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<label>Prérequis</label>
							<b-form-textarea
								v-model="form.prerequisites"
								placeholder="Prérequis"
							></b-form-textarea>
							<br />
						</b-col>
						<b-col>
							<label>Contenu & format</label>
							<b-form-textarea
								v-model="form.content"
								placeholder="Content"
							></b-form-textarea>
						</b-col>
					</b-row>
					<hr />
					<br />
					<b-row>
						<b-col>
							<div id="app">
								<label>>Pdf de présentation</label>
								<br />
								<input type="file" @change="onFileBoard($event)" />
							</div>
						</b-col>
						<b-col>
							<div id="app">
								<label>Fichiers joints (pdf)</label>
								<br />
								<input
									type="file"
									@change="onFileAttachment($event)"
									multiple
								/>
							</div>
						</b-col>
					</b-row>
				</b-form>
			</ValidationObserver>
			<br />
			<br />
			<label>Ajouter des Speakers</label>
			<b-form-select
				v-model="selected"
				:options="listSpeaker"
				class="mb-1"
			></b-form-select>
			<div class="text-right">
				<b-button variant="primary" size="sm" @click="addSpeaker(selected)"
					>Ajouter</b-button
				>
			</div>
			<br />
			<div>
				<b-table
					class="mr-2"
					size="sm"
					striped
					hover
					:items="form.speakers"
					:fields="fields"
				>
					<template #cell(action)="list" cols="2">
						<b-button
							variant="danger"
							class="float-right"
							size="sm"
							@click="deleteSpeaker(list.item)"
							>Supprimer
						</b-button>
					</template>
				</b-table>
			</div>

			<template #modal-footer>
				<div class="w-100">
					<b-button
						variant="primary"
						size="sm"
						class="float-right ml-1"
						@click="onSubmit"
						>Créer</b-button
					>
					<b-button
						variant="danger"
						size="sm"
						class="float-right"
						@click="hideModal"
						>Fermer</b-button
					>
				</div>
			</template>
		</b-modal>
	</div>
</template>

<script>
import {
	BModal,
	BButton,
	BForm,
	BFormDatepicker,
	BFormSelect,
	BAvatar,
	BMedia,
	BRow,
	BCol,
	BFormInput,
	BFormTextarea,
	BTable,
	BFormCheckbox,
	BFormGroup,
} from "bootstrap-vue";
import { Admin } from "@/mixins/admin.js";

import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";

Object.keys(rules).forEach((rule) => {
	extend(rule, rules[rule]);
});

extend("required", { message: "Ce champ ne peut pas être vide" });
extend("numeric", { message: "Ce champ doit être un nombre" });

export default {
	name: "WebinarCreateModal",

	components: {
		BModal,
		BButton,
		BForm,
		BFormDatepicker,
		BFormSelect,
		BAvatar,
		BRow,
		BMedia,
		BCol,
		BFormInput,
		BFormTextarea,
		ValidationProvider,
		ValidationObserver,
		BTable,
		BFormCheckbox,
		BFormGroup,
	},

	mixins: [Admin],

	data() {
		return {
			listSurvey: [],
			listQuiz: [],
			listSpeaker: [],
			show: false,
			form: {
				title: "",
				full_url: "",
				price: "",
				content: "",
				media_id: "",
				learning_objective: "",
				prerequisites: "",
				learning_content: "",
				start_date: "",
				controle_frequency: "",
				header: "",
				footer: "",
				survey_id: "",
				pre_quizz: "",
				post_quizz: "",
				minimal_certificat_score: "",
				point_ceu: 0,
				point_cfc: 0,
				recording: 0,
				duration: 0,
				speakers: [],
				type: "",
			},
			fields: [
				{
					key: "name",
					label: "Nom",
					sortable: true,
				},
				{
					key: "Action",
					label: "Action",
					sortable: false,
					thClass: "text-right",
					tdClass: "text-right",
				},
			],
			typeWebinar: [
				{
					text: "General",
					value: "GENERAL",
				},
				{
					text: "Ethic",
					value: "ETHIC",
				},
				{
					text: "Supervisor",
					value: "SUPERVISOR",
				},
			],
			timeStart: "",
			my_photo: "",
			selected: "",
			currentImage: undefined,
			boardImage: undefined,
			attachment: undefined,
		};
	},

	mounted() {
		this.getListSurveys();
		this.getListQuiz();
		this.getListSpeaker();
	},

	methods: {
		async getListSpeaker() {
			const response = await this.getAllSpeaker();
			let listSpeaker = response.datas;

			for (let i = 0; i < listSpeaker.length; i++) {
				let add = {
					text: listSpeaker[i].firstname + " " + listSpeaker[i].lastname,
					value: listSpeaker[i],
				};
				this.listSpeaker.push(add);
			}

			return this.listSpeaker;
		},

		addSpeaker(option) {
			if (option) {
				const index = this.form.speakers.findIndex(
					(speaker) => speaker.id === option.id
				);
				if (!~index) {
					this.form.speakers.push({
						name: option.firstname + " " + option.lastname,
						id: option.id,
					}); //delete the post
				}
			}
		},

		deleteSpeaker(selected) {
			const index = this.form.speakers.findIndex(
				(speaker) => speaker.id === selected.id
			);
			if (~index) {
				this.form.speakers.splice(index, 1); //delete the post
			}
		},

		async getListSurveys() {
			const response = await this.getAllSurveys();
			let listSurvey = response.datas;
			let empty = { text: "", value: "" };
			this.listSurvey.push(empty);
			for (let i = 0; i < listSurvey.length; i++) {
				let add = {
					text: listSurvey[i].name,
					value: listSurvey[i],
				};
				this.listSurvey.push(add);
			}
			return this.listSurvey;
		},

		async getListQuiz() {
			const response = await this.getAllQuiz();
			let listQuiz = response.datas;
			let empty = { text: "", value: "" };
			this.listQuiz.push(empty);
			for (let i = 0; i < listQuiz.length; i++) {
				let add = {
					text: listQuiz[i].name,
					value: listQuiz[i],
				};
				this.listQuiz.push(add);
			}
			return this.listQuiz;
		},

		onFileInput(event) {
			this.currentImage = event;
			const data = URL.createObjectURL(event.target.files[0]);
			this.my_photo = data;
		},

		onFileBoard(event) {
			this.boardImage = event;
		},

		onFileAttachment(event) {
			this.attachment = event;
			console.log(this.attachment);
		},

		async onSubmit() {
			const isValid = await this.$refs.observer.validate();
			if (isValid) {
				this.form.start_date = this.form.start_date + " " + this.timeStart;

				this.form.survey_id = this.form.survey_id.id;
				this.form.pre_quizz = this.form.pre_quizz.id;
				this.form.post_quizz = this.form.post_quizz.id;

				let ids = this.form.speakers.map((speaker) => speaker.id);
				this.form.speakers = ids;
				console.log("speakers", this.form.speakers);
				
				let loader = this.$loading.show({
					container: this.$refs.formContainer,
					canCancel: true,
				});

				try {
					const response = await this.createWebinar(this.form);
					console.log(" reponse ssok");
					console.log(response);
					if (response.status == 0) {
						//@dev alert pour dire qu'il y a eu un probleme
						console.log("erreur");
						this.$sweetError("ADD-WEBINAR");
					} else {
						if (this.currentImage) {
							await this.uploadIMG(
								this.currentImage,
								response.datas.id,
								"WEBINAR_MEDIA"
							);
						}
						if (this.boardImage) {
							await this.uploadIMG(
								this.boardImage,
								response.datas.id,
								"WEBINAR_DOC_INTRO"
							);
						}
						if (this.attachment) {
							await this.uploadMultiFile(
								this.attachment,
								response.datas.id,
								"WEBINAR_DOCS"
							);
						}
						loader.hide();
						this.$emit("clicked", response.datas);
						this.hideModal();
						this.$sweetNotif("Ajout réussi");
					}
				} catch {
					console.log("problem");
					loader.hide();
					this.$sweetError("AF-51");
				}
			}
		},

		validateForm() {
			let result = true;
			if (
				!this.form.lastname &&
				!this.form.firstname &&
				!this.form.email &&
				!this.form.biography &&
				!this.form.speciality &&
				!this.form.isActive
			) {
				result = false;
			}
			return result;
		},

		hideModal() {
			this.cancelForm();
			this.$refs["modal-webinar-create"].hide();
		},

		cancelForm() {
			this.form = {
				title: "",
				full_url: "",
				price: "",
				content: "",
				media_id: "",
				learning_objective: "",
				prerequisites: "",
				learning_content: "",
				start_date: "",
				controle_frequency: "",
				header: "",
				footer: "",
				survey_id: "",
				pre_quizz: "",
				post_quizz: "",
				minimal_certificat_score: "",
				point_ceu: 0,
				point_cfc: 0,
				recording: 0,
				duration: 0,
				speakers: [],
				type: "",
			};
			this.timeStart = "";
			this.my_photo = "";
			this.currentImage = undefined;
			this.boardImage = undefined;
			this.attachment = undefined;
		},
	},

	computed: {
		emailState() {
			let result = true;
			var validRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			if (!this.form.email.match(validRegex)) {
				result = false;
			}
			return result;
		},

		lastnameState() {
			return this.form.lastname.length > 2 ? true : false;
		},
		firstnameState() {
			return this.form.firstname.length > 2 ? true : false;
		},
		biographyState() {
			return this.form.biography.length > 2 ? true : false;
		},
		specialityState() {
			return this.form.speciality.length > 2 ? true : false;
		},
	},
};
</script>

<style></style>
